import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
export class CriterioAceitacaoModel {
  constructor({
    id,
    nome,
    tipoInstrumento,
    cliente,
    setores,
    tabela,
    unidadeMedida,
    amplitudeMinima,
    amplitudeMaxima,
    resolucao,
    tipoReferencia,
    valorReferencia,
    formulaValidacao,
    flagCriterioGenerico = false,
    instrumentos,
    valorReferenciaFinal,
    formula
  }) {
    this.id = id;
    this.nome = nome;
    if (tipoInstrumento)
      this.tipoInstrumento = new DropdownModel(tipoInstrumento);
    this.cliente = cliente
    if(cliente) this.cliente = new DropdownModel(cliente);
    this.setores = setores;
    if (setores) this.setores = new DropdownModel(setores);
    this.tabela = tabela;
    if (tabela) this.tabela = new DropdownModel (tabela);
    if (unidadeMedida) this.unidadeMedida = new DropdownModel(unidadeMedida);
    this.amplitudeMaxima = amplitudeMaxima;
    this.amplitudeMinima = amplitudeMinima;
    this.resolucao = resolucao;
    this.tipoReferencia = tipoReferencia;
    this.valorReferencia = valorReferencia;
    this.formulaValidacao = formulaValidacao;
    this.flagCriterioGenerico = flagCriterioGenerico;
    this.instrumento = instrumentos
    if (instrumentos) this.instrumento = new DropdownModel(instrumentos);
    this.valorReferenciaFinal = valorReferenciaFinal;
    this.formulaId = null;
    if(formula) this.formulaId = new DropdownModel(formula);
  }

  get request() {
    let retorno = _.cloneDeep(this);

    retorno.tipoInstrumentoId = this.tipoInstrumento?.value
    retorno.clienteId = this.cliente?.value;
    retorno.setoresIds = this.setores?.map(({ value }) => value);
    retorno.unidadeMedidaId = this.unidadeMedida?.value;
    retorno.instrumentosId = this.instrumento?.map(item => item.value);
    retorno.tabelaId = this.tabela?.value;
    if(this.formulaValidacao == 'Formula')
      retorno.formulaId = retorno.formulaId.value;
    return retorno;
  }
}
