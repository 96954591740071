<template>
  <div>
    <div
      v-if="!emModal"
      class="d-flex mb-8"
    >
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="form.nome"
        class="col-12 col-md-3"
        :label="$t('modulos.criterio_aceitacao.formulario.nome')"
        :max="100"
        obrigatorio
        :disabled="apenasVisualizar"
      />
      <input-tipo-instrumento
        v-model="form.tipoInstrumento"
        class="col-12 col-md-3"
        :label="$t('modulos.criterio_aceitacao.formulario.tipo_instrumento')"
        :placeholder="$t('geral.inputs.selecione')"
        obrigatorio
        :multiplos="false"
        :disabled="apenasVisualizar"
      />
      <input-radio
        v-if="participanteLogadoConta"
        v-model="form.flagCriterioGenerico"
        class="col-12 col-md-2"
        :options="opcoes.booleano"
        :label="$t('modulos.criterio_aceitacao.formulario.criterio_generico')"
        :divider="false"
        :disabled="apenasVisualizar"
        @input="removerClienteSetor"
      />
      <input-cliente
        v-model="form.cliente"
        class="col-12 col-md-2"
        :label="$t('modulos.criterio_aceitacao.formulario.cliente')"
        :disabled="desabilitarInputCliente || apenasVisualizar"
        :obrigatorio="!form.flagCriterioGenerico"
      />
      <input-setor
        v-model="form.setores"
        class="col-12 col-md-2"
        :label="$t('modulos.criterio_aceitacao.formulario.setor')"
        :placeholder="$t('geral.inputs.selecione')"
        :disabled="form.flagCriterioGenerico || !form.cliente || apenasVisualizar"
        :participante-id="form.cliente?.value"
        multiplos
      />
      <input-instrumento
        v-model="form.instrumento"
        class="col-12 col-md-3"
        :label="$t('modulos.criterio_aceitacao.formulario.instrumento')"
        :placeholder="$t('geral.inputs.selecione')"
        :disabled="desabilitarInputInstrumento() || apenasVisualizar"
        :cliente-selecionado="form.cliente"
        :tipos-instrumentos="form.tipoInstrumento"
        multiplos
      />
      <input-tabela-registro
        v-model="form.tabela"
        class="col-12 col-md-2"
        :label="$t('modulos.criterio_aceitacao.formulario.tabela_registro')"
        :placeholder="$t('geral.inputs.selecione')"
        :multiplos="false"
        :disabled="form.flagCriterioGenerico || desabilitarTabela() || apenasVisualizar"
        :tipos-instrumentos="form.tipoInstrumento"
        flag-ultima-publicada
      />
      <input-unidade-medida
        v-model="form.unidadeMedida"
        class="col-12 col-md-2"
        :label="$t('modulos.criterio_aceitacao.formulario.unidade_medida')"
        :placeholder="$t('geral.inputs.selecione')"
        obrigatorio
        :disabled="apenasVisualizar"
        :multiplos="false"
      />
      <v-form
        ref="amplitudeForm"
        class="col-12 col-md-3 d-flex align-top"
      >
        <input-text
          v-model="form.amplitudeMinima"
          class="flex-fill"
          :label="$t('modulos.criterio_aceitacao.formulario.amplitude')"
          :placeholder="
            $t('modulos.criterio_aceitacao.formulario.amplitude_min')
          "
          obrigatorio
          :regras-personalizadas="regraAmplitude()"
          type="number"
          :disabled="apenasVisualizar"
          @input="valitarAmpliturde"
        />
        <span class="mx-2 mt-11">{{ $t('geral.titulos.a') }}</span>
        <input-text
          v-model="form.amplitudeMaxima"
          class="flex-fill mt-5"
          :disabled="apenasVisualizar"
          :placeholder="
            $t('modulos.criterio_aceitacao.formulario.amplitude_max')
          "
          obrigatorio
          :regras-personalizadas="regraAmplitude()"
          type="number"
          @input="valitarAmpliturde"
        />
      </v-form>
      <input-text
        v-model="form.resolucao"
        class="col-12 col-md-2"
        :disabled="apenasVisualizar"
        :label="$t('modulos.criterio_aceitacao.formulario.resolucao')"
        type="number"
      />
      <input-select
        v-model="form.formulaValidacao"
        :options="opcoes.formulaValidacao"
        class="col-12 col-md"
        :label="$t('modulos.criterio_aceitacao.formulario.formula_validacao')"
        obrigatorio
        :disabled="apenasVisualizar"
        :loading="loading.formulaValidacaoLoading"
      />
      <input-select
        v-model="form.tipoReferencia"
        class="col-12 col-md"
        obrigatorio
        :label="$t('modulos.criterio_aceitacao.formulario.tipo_referencia')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="opcoesTipoReferencia"
        :disabled="apenasVisualizar"
        :loading="loading.tipoReferenciaLoading"
      />
      <input-text
        v-model="form.valorReferencia"
        class="col-12 col-md"
        obrigatorio
        :label="$t('modulos.criterio_aceitacao.formulario.valor_referencia')"
        type="number"
        :disabled="apenasVisualizar"
        :regras-personalizadas="regraValorReferencia()"
      />
      <input-text
        v-model="form.valorReferenciaFinal"
        class="col-12 col-md"
        :obrigatorio="
          formulaValidacaoEhFormula && form.tipoReferencia == 'EntreDoisValores'
        "
        :disabled="
          !formulaValidacaoEhFormula ||
            form.tipoReferencia != 'EntreDoisValores' || apenasVisualizar
        "
        :label="
          $t('modulos.criterio_aceitacao.formulario.valor_referencia_final')
        "
        type="number"
        :regras-personalizadas="regraValorReferencia()"
      />
      <input-formula
        v-model="form.formulaId"
        class="col-12 col-md"
        :obrigatorio="formulaValidacaoEhFormula"
        :disabled="!formulaValidacaoEhFormula || apenasVisualizar"
        :label="$t('modulos.criterio_aceitacao.formulario.formula')"
        :titulo-modal="
          $t('modulos.criterio_aceitacao.formulario.titulo_modal_formula')
        "
        :multiplos="false"
        disponivel-criterio-aceitacao
      />
    </v-form>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ apenasVisualizar ? $t('geral.botoes.voltar') : $t('geral.botoes.cancelar') }}
      </botao-padrao>

      <botao-padrao
        v-if="!apenasVisualizar"
        @click="confirmacaoSalvar"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import CriterioAceitacaoService from '@common/services/cadastros/CriterioAceitacaoService.js';
import { CriterioAceitacaoModel } from '@common/models/cadastros/CriterioAceitacaoModel.js';
import ParticipanteService from '@common/services/cadastros/ParticipanteService';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import { InputFormula } from '@components/inputs';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';

import {
  InputTipoInstrumento,
  InputCliente,
  InputSetor,
  InputUnidadeMedida,
  InputInstrumento,
  InputTabelaRegistro,
} from '@components/inputs';
import { mapGetters } from 'vuex';

export default {
  components: {
    InputTipoInstrumento,
    InputCliente,
    InputSetor,
    InputUnidadeMedida,
    InputInstrumento,
    InputTabelaRegistro,
    InputFormula,
  },
  props: ['id', 'mostraCriteriosModalAnaliseTecnica', 'emModal', 'instrumentoId'],
  data() {
    return {
      valido: false,
      form: new CriterioAceitacaoModel({}),
      clienteWatcher: null,
      instrumentoWatcher: null,
      tabelaWatcher: null,
      opcoes: {
        booleano: helpers.BoleanoEnum,
        formulaValidacao: [],
        tipoReferencia: [],
      },
      loading: {
        formulaValidacaoLoading: false,
        tipoReferenciaLoading: false,
      },
    };
  },

  computed: {
    ...mapGetters('Autenticacao', [
      'participanteSelecionado',
      'participanteLogadoConta',
    ]),
    formulaValidacaoEhFormula: function () {
      return this.form.formulaValidacao == 'Formula';
    },
    desabilitarInputCliente: function () {
      if (!this.participanteLogadoConta) return true;
      return this.form.flagCriterioGenerico;
    },
    apenasVisualizar: function () {
      return this.$route.name.includes('visualizar');
    },
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.criterio_aceitacao.titulos.editar');
      return this.$t('modulos.criterio_aceitacao.titulos.novo');
    },
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t(
        'modulos.criterio_aceitacao.validacoes.formulario_invalido'
      );
    },
    opcoesTipoReferencia: function () {
      return this.opcoes.tipoReferencia
        .filter((el) => {
          if (this.form.formulaValidacao == 'Formula') return true;
          return !['EntreDoisValores', 'MenorQue', 'MaiorQue'].includes(
            el.value
          );
        })
        .sort(helpers.ordenarDropdown);
    },
  },
  watch: {
    'form.flagCriterioGenerico': function () {
      if (this.form.flagCriterioGenerico) {
        this.form.cliente = null;
        this.form.setor = null;
        this.form.instrumento = null;
        this.form.tabela = null;
      }
    },

    'form.formulaValidacao': function () {
      if (this.form.formulaValidacao != 'Formula') {
        this.form.valorReferenciaFinal = null;
        this.form.formulaId = null;
      }
    },

    'form.tipoReferencia': function () {
      if (this.form.tipoReferencia != 'EntreDoisValores') {
        this.form.valorReferenciaFinal = null;
      }
    },

  },
  async mounted() {
    await this.buscarTipoReferencia();
    await this.buscarFormulaValidacaoEnum();
    this.form = new CriterioAceitacaoModel({});
    if (!this.participanteLogadoConta && !this.id)
      await this.buscarParticipante(this.participanteSelecionado);
      if (this.id) {
      await this.buscar(this.id);
      helpers.redirecionarSemPermissao(this, 'CriteiroAceitacao', 'Deletar');
    }
    helpers.redirecionarSemPermissao(this, 'CriteiroAceitacao', 'Inserir');

    if (this.clienteWatcher == null) {
      this.clienteWatcher = this.$watch(
        'form.cliente',
        (novoCliente, cliente) => {
          if (novoCliente.value != cliente.value) {
            this.form.instrumento = null;
            this.form.tabela = null;
            this.form.setores = null;
          }
        }
      );
    }
    if (this.instrumentoWatcher == null)
      this.instrumentoWatcher = this.$watch(
        'form.tipoInstrumento',
        (newInst, inst) => {
          if (newInst.value != inst.value) {
            this.form.instrumento = null;
            this.form.tabela = null;
          }
        }
      );
    if (this.tabelaWatcher == null)
      this.instrumentoWatcher = this.$watch('form.instrumento', () => {
        this.form.tabela = null
      });

    this.buscarInstrumento();
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscarInstrumento(){
      if(!this.instrumentoId || this.id) return;
      InstrumentoService.buscar(this.instrumentoId).then((res) => {
        this.form.cliente = new DropdownModel(res.data.participanteCliente);
        this.form.tipoInstrumento = new DropdownModel(res.data.tipoInstrumento);
        const instrumento = {id: res.data.id, nome: res.data.codigoTag}
        
        this.$nextTick().then(() => this.form.instrumento = new DropdownModel([instrumento]))        
      });
    },
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    desabilitarInputInstrumento: function () {
      return (
        !this.form.cliente ||
        !this.form.tipoInstrumento
      );
    },
    regraAmplitude: function () {
      if (!this.form.amplitudeMaxima || !this.form.amplitudeMinima) return [];

      let regraValor = (v) => {
        let valor = `${v}`;
        let intValue = valor.split('.')[0];
        let decimalValue = valor.split('.')[1];
        if (intValue.length > 10) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            char: 10,
            decimal: 9,
          });
        }

        if (decimalValue && decimalValue.length > 9) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            decimal: 9,
            char: 10,
          });
        }

        return true;
      };

      let regraValorMaior = () =>
        parseFloat(this.form.amplitudeMaxima) >=
        parseFloat(this.form.amplitudeMinima)
          ? true
          : this.$t(
              'inputs.rules.amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima'
            );
      return [regraValor, regraValorMaior];
    },
    regraValorReferencia: function () {
      if (!this.form.valorReferencia) return [];

      if (
        this.form.tipoReferencia == 'PorcentagemPontoLido' ||
        this.form.tipoReferencia == 'PorcentagemFundoEscala'
      ) {
        const regraValor = (v) => {
          if (parseFloat(v) > 100 || parseFloat(v) < 0)
            return this.$t('geral.erros.valorReferenciaDeveEstarEntre0a100');

          const valor = `${v}`;
          if (valor.split('.').length > 1) {
            const decimalValue = valor.split('.')[1];
            if (decimalValue.length > 2)
              return this.$t('inputs.rules.maxBetweenDecimalValue', {
                char: 3,
                decimal: 2,
              });
          }
          return true;
        };
        return [regraValor];
      }

      return [];
    },
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await CriterioAceitacaoService.buscar(this.id)
        .then((res) => {
          this.form = new CriterioAceitacaoModel(res.data);
          this.$refs.form.validate();
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.criterio_aceitacao.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      if (!this.$refs.form.validate()) return;

      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CriterioAceitacaoService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.criterio_aceitacao.cadastro_sucesso`)
          );
          if (!this.mostraCriteriosModalAnaliseTecnica) {
            this.$router.push({ name: 'criterio-aceitacao' });
          } else {
            this.form = new CriterioAceitacaoModel({});
            this.$emit('retornaParaIndexModal');
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      if (!this.mostraCriteriosModalAnaliseTecnica) {
        this.confirmarCancelar().then(() => {
          this.$router.push({ name: 'criterio-aceitacao' });
        });
      } else {
        this.form = new CriterioAceitacaoModel({});
        this.$emit('retornaParaIndexModal');
      }
    },
    buscarFormulaValidacaoEnum: async function () {
      this.loading.formulaValidacaoLoading = true;
      await EnumeradorService.buscar('EnumFormulaValidacaoCriterioValidacao')
        .then((res) => {
          res.sort(helpers.ordenarDropdown);
          this.opcoes.formulaValidacao = res;
        })
        .finally(() => {
          this.loading.formulaValidacaoLoading = false;
        });
    },
    buscarTipoReferencia: async function () {
      this.loading.tipoReferenciaLoading = true;
      await EnumeradorService.buscar('EnumTipoReferencia')
        .then((res) => {
          this.opcoes.tipoReferencia = res;
        })
        .finally(() => {
          this.loading.tipoReferenciaLoading = false;
        });
    },
    buscarParticipante: async function (id) {
      await ParticipanteService.buscar(id).then((res) => {
        this.form.cliente = new DropdownModel(res.data);
      });
    },
    removerClienteSetor() {
      this.form.cliente = null;
      this.form.setores = null;
    },
    valitarAmpliturde() {
      if (!this.form.amplitudeMinima && !this.form.amplitudeMaxima) return;
      this.$refs.amplitudeForm.validate();
    },
    desabilitarTabela() {
      return !(!!this.form.cliente && !!this.form.tipoInstrumento);
    },
  },
};
</script>
